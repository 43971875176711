import React, { useState, useEffect, useRef } from "react";

const Milesight = () => {
  const [data, setData] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [prevPage, setPrevPage] = useState(null);
  const [onClose, setOnClose] = useState(false);
  const [picture, setPicture] = useState();
  const modalRef = useRef(null);
  const link = "https://milesight.trafficiot.com/api/store-event-data";
  const imgLnk =
    "https://milesight.trafficiot.com/milesight/storage/app/public/";

  useEffect(() => {
    fetchData(link);
  }, [link]);

  const fetchData = async (link) => {
    if (!link) return;
    try {
      const response = await fetch(link);
      if (response.ok) {
        response.json().then((data) => {
          setNextPage(data.next);
          setPrevPage(data.previous);
          setData(data.results);
        });
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const handleView = (address) => {
    setPicture(`${imgLnk}${address}`);
    setOnClose(true);
  };

  return (
    <div className="mt-14 p-10">
      <div className="">
        <div className="flex justify-between border mb-2 bg-indigo-950 text-white font-semibold text-center min-w-[752px]">
          <div className="w-12 p-1 py-2">Serial</div>
          <div className="w-48 p-1 py-2">Device Name</div>
          <div className="w-60 p-1 py-2">Time Stamp</div>
          <div className="w-48 p-1 py-2">Speed</div>
          <div className="w-48 p-1 py-2">Event Type</div>
          <div className="w-48 p-1 py-2">License Plate No</div>
          <div className="w-48 p-1 py-2">License Plate</div>
          <div className="w-48 p-1 py-2">Vehicle Snapshot</div>
          <div className="w-48 p-1 py-2">Full Snapshot</div>
          <div className="w-48 p-1 py-2">Violation Snapshot</div>
          <div className="w-48 p-1 py-2">Vehicle Type</div>
        </div>
        {data &&
          data.map((item, index) => {
            return (
              <div
                key={index}
                className="flex justify-between items-center border mb-2 min-w-[752px]"
              >
                <div className="w-12 p-1 py-2 text-center">{index + 1}</div>
                <div className="w-48 p-1 py-2">{item.device_name}</div>
                <div className="w-60 p-1 py-2">{item.time}</div>
                <div className="w-48 p-1 py-2 text-center">{item.speed}</div>
                <div className="w-48 p-1 py-2 text-center">
                  {item.event_type}
                </div>
                <div className="w-48 p-1 py-2 text-center">
                  {item.license_plate}
                </div>
                <div className="w-48 p-1 py-2" onClick={() => handleView(item.license_plate_snapshot)}>
                  <img
                    className="w-auto h-12 hover:scale-105 transition-all cursor-pointer"
                    src={`${imgLnk}${item.license_plate_snapshot}`}
                    alt="snapshot"
                  />
                </div>
                <div className="w-48 p-1 py-2" onClick={() => handleView(item.vehicle_snapshot)}>
                  <img
                    className="w-auto h-12 hover:scale-105 transition-all cursor-pointer"
                    src={`${imgLnk}${item.vehicle_snapshot}`}
                    alt="snapshot"
                  />
                </div>
                <div className="w-48 p-1 py-2" onClick={() => handleView(item.full_snapshot)}>
                  <img
                    className="w-auto h-12 hover:scale-105 transition-all cursor-pointer"
                    src={`${imgLnk}${item.full_snapshot}`}
                    alt="snapshot"
                  />
                </div>
                <div className="w-48 p-1 py-2" onClick={() => handleView(item.violation_snapshot)}>
                  <img
                    className="w-auto h-12 hover:scale-105 transition-all cursor-pointer"
                    src={`${imgLnk}${item.violation_snapshot}`}
                    alt="snapshot"
                  />
                </div>
                <div className="w-48 p-1 py-2 text-center">
                  {item.vehicle_type}
                </div>
              </div>
            );
          })}
      </div>

      <div className="flex gap-5 my-5 float-right">
        <button
          onClick={() => fetchData(prevPage)}
          className="bg-blue-500 rounded text-white font-semibold w-20 py-1 hover:bg-blue-600"
          disabled={!prevPage}
        >
          Previous
        </button>
        <button
          onClick={() => fetchData(nextPage)}
          className="bg-blue-500 rounded text-white font-semibold w-20 py-1 hover:bg-blue-600"
          disabled={!nextPage}
        >
          Next
        </button>
      </div>
      {onClose && (
        <div className="fixed inset-0 flex items-center justify-center z-50 p-10">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div
            className="relative bg-white rounded-lg shadow-lg"
            ref={modalRef}
          >
            <button
              className="absolute top-2 right-2 text-white hover:text-red-500 bg-black rounded opacity-80 p-1"
              onClick={() => setOnClose(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <img
              className="max-w-full max-h-screen"
              src={picture}
              alt="Preview"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Milesight;
